import React, { useEffect, useState, useRef } from "react";
import Sortable from "sortablejs";
import { getallAgent, deleteAgent, deactivateClient, activeClient, assignStaffToEnquiries, getFilterAgent, getAllAgentCard} from "../../api/agent";
import { Link, useLocation } from "react-router-dom";
import { getSuperAdminForSearch } from "../../api/superAdmin";
import { getallStaff } from "../../api/staff";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Pagination,
  radioClasses,
} from "@mui/material";
import { formatDate } from "../../Utils/DateFormat";
import Mastersidebar from "../../compoents/sidebar";
import { ExportCsvService } from "../../Utils/Excel";
import { templatePdf } from "../../Utils/PdfMake";
import { toast } from "react-toastify";
import { getAgentId, getSuperAdminId } from "../../Utils/storage";
import { FaFilter } from "react-icons/fa";
import axios from "axios";

export default function Masterproductlist() {
  const initialStateInputs = {
    agentName: "",
    agentCode: "",
    mobileNumber: "",
    businessName: "",
  };


  const [selectedIds, setSelectedIds] = useState([]); // To track selected checkboxes
  const [openDelete, setOpenDelete] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [staff, setStaff] = useState([]);
  const [file, setFile] = useState(null);
  const [selectedStaffId, setSelectedStaffId] = useState('');
  const [selectedStaffName, setSelectedStaffName] = useState(''); // To store the staff name
  const location = useLocation();
  var searchValue = location.state;
  const [link, setLink] = useState("");
  const [data, setData] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [filter, setFilter] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [pageSize, setPageSize] = useState(10);
  const search = useRef(null);
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize,
  });

  const [agent, setAgent] = useState([]);
  const [details, setDetails] = useState();

  useEffect(() => {
    if (search.current) {
      search.current.focus();
    }
  }, []);

  useEffect(() => {
    if (searchValue) {
      search.current.value = searchValue.substring(1);
      handleSearch();
    }
  }, [searchValue]);
  useEffect(() => {
    getAllAgentDetails();
    getStaffList();
  }, [pagination.from, pagination.to, pageSize]);


  useEffect(() => {
    getAllAgentCount()

  })

  const getAllAgentCount = () => {
    getAllAgentCard().then((res) => setDetails(res?.data.result))
  }


  const getStaffList = () => {
    getallStaff()
      .then((res) => {
        setStaff(res?.data?.result || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllAgentDetails = () => {
    const data = {
      limit: pageSize, // Use dynamic page size here
      page: pagination.from,
    };
    getFilterAgent(data)
      .then((res) => {
        setAgent(res?.data?.result?.agentList);
        setPagination({
          ...pagination,
          count: res?.data?.result?.agentCount,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlePageChange = (event, page) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;
    setPagination({ ...pagination, from: from, to: to });
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value)); // Update page size when dropdown changes
    setPagination({ ...pagination, from: 0, to: Number(event.target.value) }); // Reset pagination
  };

  const handleInputsearch = (event) => {
    if (event.key === "Enter") {
      search.current.blur();
      handleSearch();
    }
  };

  const handleSearch = (event) => {
    const data = search.current.value;
    event?.preventDefault();
    getSuperAdminForSearch(data)
      .then((res) => {
        const universityList = res?.data?.result?.agentListed;
        setAgent(universityList);
        const result = universityList.length ? "Agent" : "";
        setLink(result);
        setData(result === "" ? true : false);
      })
      .catch((err) => console.log(err));
  };
  const openPopup = (data) => {
    setOpen(true);
    setDeleteId(data);
  };

  const closePopup = () => {
    setOpen(false);
  };
  const deleteAgentData = () => {
    deleteAgent(deleteId)
      .then((res) => {
        toast.success(res?.data?.message);
        closePopup();
        getAllAgentDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openFilterPopup = () => {
    setOpenFilter(true);
  };

  const closeFilterPopup = () => {
    setOpenFilter(false);
  };
  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };
  const filterAgentList = (event) => {
    event?.preventDefault();
    setFilter(true);
    const data = {
      agentName: inputs.agentName,
      agentCode: inputs.agentCode,
      mobileNumber: inputs.mobileNumber,
      businessName: inputs.businessName,
      limit: 10,
      page: pagination.from,
    };
    getFilterAgent(data)
      .then((res) => {
        setAgent(res?.data?.result?.agentList);
        setPagination({
          ...pagination,
          count: res?.data?.result?.agentCount,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetFilter = () => {
    setFilter(false);
    setInputs(initialStateInputs);
    getAllAgentDetails();
  };


  const openImportPopup = () => {
    setOpenImport(true);
  };

  const closeImportPopup = () => {
    setOpenImport(false);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("program", file);

    try {
      const response = await axios.post(
        "https://api.edufynd.in/api/agent/import",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const pdfDownload = (event) => {
    event?.preventDefault();
    getallAgent(agent)
      .then((res) => {
        var result = res?.data?.result;
        var tablebody = [];
        tablebody.push([
          {
            text: "S.NO",
            fontSize: 11,
            alignment: "center",
            margin: [5, 5],
            bold: true,
          },
          {
            text: "Agent Name",
            fontSize: 11,
            alignment: "center",
            margin: [20, 5],
            bold: true,
          },
          {
            text: "Business Name",
            fontSize: 11,
            alignment: "center",
            margin: [20, 5],
            bold: true,
          },
          {
            text: "Agent Code",
            fontSize: 11,
            alignment: "center",
            margin: [20, 5],
            bold: true,
          },
          {
            text: "Mobile Number",
            fontSize: 11,
            alignment: "center",
            margin: [20, 5],
            bold: true,
          },
          {
            text: "Staff Name",
            fontSize: 11,
            alignment: "center",
            margin: [20, 5],
            bold: true,
          },
        ]);
        result.forEach((element, index) => {
          tablebody.push([
            {
              text: index + 1,
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
              border: [true, false, true, true],
            },
            {
              text: element?.agentName ?? "-",
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },
            {
              text: element?.businessName ?? "-",
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },

            {
              text: element?.agentCode ?? "-",
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },
            {
              text: element?.staffName ?? "-",
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },
            {
              text: element?.mobileNumber ?? "-",
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },
          ]);
        });
        templatePdf("agent List", tablebody, "landscape");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportCsv = (event) => {
    event?.preventDefault();
    getallAgent(agent)
      .then((res) => {
        var result = res?.data?.result;
        let list = [];
        result?.forEach((res) => {
          list.push({
            agentName: res?.agentName ?? "-",
            businessName: res?.businessName ?? "-",
            agentCode: res?.agentCode ?? "-",
            staffName: res?.staffName ?? "-",
            mobileNumber: res?.mobileNumber ?? "-",
          });
        });
        let header1 = [
          "agentName",
          "businessName",
          "agentCode",
          "staffName",
          "mobileNumber",
        ];
        let header2 = [
          "Agent Name",
          "Business Name",
          "Agent Code",
          "Staff Name",
          "Mobile Number",
        ];
        ExportCsvService.downloadCsv(
          list,
          "agentList",
          "Agent List",

          header1,
          header2
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tableRef = useRef(null);

  useEffect(() => {
    const table = tableRef.current;

    // Apply SortableJS to the table headers
    const sortable = new Sortable(table.querySelector("thead tr"), {
      animation: 150,
      swapThreshold: 0.5,
      handle: ".sortable-handle",
      onEnd: (evt) => {
        const oldIndex = evt.oldIndex;
        const newIndex = evt.newIndex;

        // Move the columns in the tbody
        table.querySelectorAll("tbody tr").forEach((row) => {
          const cells = Array.from(row.children);
          row.insertBefore(cells[oldIndex], cells[newIndex]);
        });
      },
    });

    return () => {
      sortable.destroy();
    };
  }, []);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = agent.map((data) => data._id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleActionChange = (event) => {
    const action = event.target.value;
    if (action === "Delete") {
      setOpenDelete(true);
      // deleteSelectedagent();
    } else if (action === "Activate") {
      activateSelectedAgent();
    } else if (action === "DeActivate") {
      deactivateSelectedAgent();
    } else if (action === "Assign") {
      // activateSelectedAgent();
      setOpenAssign(true);
    }
  };



  const deleteSelectedagent = () => {
    if (selectedIds.length > 0) {
      Promise.all(selectedIds.map((id) => deleteAgent(id)))
        .then((responses) => {
          toast.success("agent deleted successfully!");
          setSelectedIds([]);
          setOpenDelete(false);
          getAllAgentDetails();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to delete agent.");
        });
    } else {
      toast.warning("No agent selected.");
    }
  };



  const activateSelectedAgent = () => {
    if (selectedIds.length > 0) {
      // Send the selected IDs to the backend to activate the clients
      activeClient({ agentIds: selectedIds })
        .then((response) => {
          console.log("Response:", response);
          toast.success("Agent activated successfully!");
          setSelectedIds([]); // Clear selected IDs after successful activation
          getAllAgentDetails(); // Refresh the client list
        })
        .catch((err) => {
          console.error(err);
          toast.error("Already activate Agent.");
        });
    } else {
      toast.warning("No selected Agent.");
    }
  };

  const deactivateSelectedAgent = () => {
    if (selectedIds.length > 0) {
      // Send the selected IDs to the backend to deactivate the clients
      deactivateClient({ agentIds: selectedIds })
        .then((response) => {
          console.log("Response:", response);
          toast.success("agent deactivated successfully!");
          setSelectedIds([]); // Clear selected IDs after successful deactivation
          getAllAgentDetails(); // Refresh the client list
        })
        .catch((err) => {
          console.error(err);
          toast.error("Aready to deactivate Agent.");
        });
    } else {
      toast.warning("No selected Agent.");
    }
  };

  const handleStaffSelect = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedStaffId = event.target.value;
    const selectedStaffName = event.target.options[selectedIndex].text;

    setSelectedStaffId(selectedStaffId);
    setSelectedStaffName(selectedStaffName);   // Store staff ID

  }
  const handleSubmitStaffAssign = () => {
    if (selectedIds.length > 0 && selectedStaffId) {
      assignStaffToEnquiries({ Ids: selectedIds, staffId: selectedStaffId, staffName: selectedStaffName })
        .then(() => {
          toast.success('accmmodation assigned successfully!');
          setSelectedIds([]); // Clear selected enquiries
          getAllAgentDetails(); // Refresh student enquiries
        })
        .catch((err) => {
          console.log(err);
          toast.error('Failed to assign genreal.');
        });
    } else {
      toast.warning('Please select enquiries and genreal.');
    }
  };

  return (
    <>
      <Mastersidebar />

      <div
        className="content-wrapper "
        style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px" }}
      >
        <div className="content-header bg-light shadow-sm sticky-top">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-xl-12">
                <ol className="breadcrumb d-flex justify-content-end align-items-center w-100">
                  <li className="flex-grow-1">
                    <form onSubmit={handleSearch}>
                      <div className="input-group" style={{ maxWidth: "600px" }}>
                        <input
                          type="search"
                          placeholder="Search....."
                          ref={search}
                          onChange={handleInputsearch}
                          aria-describedby="button-addon3"
                          className="form-control border-1 border-dark rounded-4"
                          style={{ fontSize: '12px' }}
                        />
                        <button
                          className="input-group-text bg-transparent border-0"
                          id="button-addon3"
                          type="submit"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          <i className="fas fa-search" style={{ color: "black" }}></i>
                        </button>
                      </div>
                    </form>
                  </li>
                  <li class="m-1">
                    <div>
                      <button
                        className="btn btn-primary"
                        style={{ fontSize: "11px" }}
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        {" "}
                        <FaFilter />
                      </button>
                      <div
                        className="offcanvas offcanvas-end"
                        tabIndex={-1}
                        id="offcanvasRight"
                        aria-labelledby="offcanvasRightLabel"
                      >
                        <div className="offcanvas-header">
                          <h5 id="offcanvasRightLabel">Filter Agent</h5>
                          <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          />
                        </div>
                        <div className="offcanvas-body ">
                          <form>
                            <div className="from-group mb-3">
                              <label className="form-label">Agent Name</label>
                              <br />
                              <input
                                type="text"
                                className="form-control"
                                name="agentName"
                                onChange={handleInputs}
                                placeholder="Search...Agent Name"
                                style={{
                                  fontFamily: "Plus Jakarta Sans",
                                  fontSize: "11px",
                                }}
                              />
                              <label className="form-label">Agent Name</label>
                              <br />
                              <input
                                type="text"
                                className="form-control"
                                name="businessName"
                                onChange={handleInputs}
                                placeholder="Search...Business Name"
                                style={{
                                  fontFamily: "Plus Jakarta Sans",
                                  fontSize: "11px",
                                }}
                              />
                              <label className="form-label">Agent Code</label>
                              <br />
                              <input
                                type="text"
                                className="form-control"
                                name="agentCode"
                                onChange={handleInputs}
                                placeholder="Search...Agent Code"
                                style={{
                                  fontFamily: "Plus Jakarta Sans",
                                  fontSize: "11px",
                                }}
                              />
                              <label className="form-label">
                                Mobile Number
                              </label>
                              <br />
                              <input
                                type="text"
                                className="form-control"
                                name="mobileNumber"
                                onChange={handleInputs}
                                placeholder="Search...MobileNumber"
                                style={{
                                  fontFamily: "Plus Jakarta Sans",
                                  fontSize: "11px",
                                }}
                              />

                            </div>
                            <div>
                              <button
                                data-bs-dismiss="offcanvas"
                                className="btn btn-cancel border-0 rounded-1 fw-semibold text-white float-right bg"
                                onClick={resetFilter}
                                style={{
                                  backgroundColor: "#0f2239",
                                  fontFamily: "Plus Jakarta Sans",
                                  fontSize: "14px",
                                }}
                              >
                                Reset
                              </button>
                              <button
                                data-bs-dismiss="offcanvas"
                                type="submit"
                                onClick={filterAgentList}
                                className="btn btn-save border-0 rounded-1 fw-semibold text-white float-right mx-2"
                                style={{
                                  backgroundColor: "#fe5722",
                                  fontFamily: "Plus Jakarta Sans",
                                  fontSize: "14px",
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="m-1">
                    <Link onClick={pdfDownload}>
                      <button
                        style={{ backgroundColor: "#E12929", fontSize: "11px" }}
                        className="btn text-white "
                      >
                        <span>
                          <i class="fa fa-file-pdf" aria-hidden="true"></i>
                        </span>
                      </button>
                    </Link>
                  </li>
                  <li class="m-1">
                    <Link onClick={exportCsv} class="btn-filters">
                      <span>
                        <button
                          style={{
                            backgroundColor: "#22A033",
                            fontSize: "11px",
                          }}
                          className="btn text-white "
                        >
                          <i class="fa fa-file-excel" aria-hidden="true"></i>
                        </button>
                      </span>
                    </Link>
                  </li>

                  <li class="m-1">
                    <Link onClick={openImportPopup} class="btn-filters">
                      <span>
                        <button
                          style={{
                            backgroundColor: "#9265cc",
                            fontSize: "11px",
                          }}
                          className="btn text-white "
                        >
                          <i class="fa fa fa-upload" aria-hidden="true"></i>
                        </button>
                      </span>
                    </Link>
                  </li>
                  <li class="m-0">
                    <Link class="btn btn-pix-primary" to="/add_agent">
                      <button
                        className="btn btn-outline  fw-semibold border-0 rounded-1 text-white  "
                        style={{ backgroundColor: "#231f20", fontSize: "12px" }}
                      >
                        <i
                          class="fa fa-plus-circle me-2"
                          aria-hidden="true"
                        ></i>{" "}
                        Add Agent
                      </button>
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>





        <div className="container-fluid mt-3 overflow-x-auto">
          <div className="row">
            {/* Card 1: Total Agents */}
            <div className="col-md-3">
              <Link to='#' className="text-decoration-none">  <div className="card rounded-1 border-0 shadow-sm" style={{ backgroundColor: '#00695c', color: '#fff' }}>
                <div className="card-body text-start">
                  <h6> <i className="fas fa-list-ul "></i>&nbsp;&nbsp;No of Agents: {details?.totalAgent || 0}</h6>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="card-text mb-1">Active: {details?.activeData || 0}</p>
                    <p className="card-text mb-1">InActive: {details?.inactiveData || 0}</p> <br></br>

                  </div>
                </div>
              </div>
              </Link>
            </div>

         {/* Card 2: Total Staff - Active Overall */}
         <div className="col-md-3">
                  <Link to='#' className="text-decoration-none">  <div className="card rounded-1 border-0 shadow-sm" style={{ backgroundColor: '#00695c', color: '#fff' }}>
                    <div className="card-body text-start">
                      <h6> <i className="fas fa-list-ul "></i>&nbsp;&nbsp;BranchWise Agent: {details?.totalStaff || 0}</h6>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="card-text mb-1">Processing...</p>
                        <p className="card-text mb-1">Processing...</p> <br></br>

                      </div>
                    </div>
                  </div>
                  </Link>
                </div>


                {/* Card 5: Total Staff - */}
                <div className="col-md-3">
                  <Link to='#' className="text-decoration-none">  <div className="card rounded-1 border-0 shadow-sm" style={{ backgroundColor: '#00695c', color: '#fff' }}>
                    <div className="card-body text-start">
                      <h6> <i className="fas fa-list-ul "></i>&nbsp;&nbsp;Highest No of Application:</h6>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="card-text mb-1">Processing...</p>
                        <p className="card-text mb-1">Processing...</p> <br></br>

                      </div>
                    </div>
                  </div>
                  </Link>
                </div>

            {/* Card 4: Pending Invoices */}
            <div className="col-md-3 col-sm-6 mb-3">
              <Link to="#" className="text-decoration-none">
                <div
                  className="card rounded-1 border-0 text-white shadow-sm"
                  style={{ backgroundColor: "#FBC02D" }} // Yellow
                >
                  <div className="card-body">
                    <h6 className="">
                      <i className="fas fa-file-invoice" style={{ color: '#ffffff' }}></i> Pending Invoices
                    </h6>
                    <p className="card-text">Total: 50</p>
                  </div>
                </div>
              </Link>
            </div>

            {/* Card 5: Paid Invoices */}

            <div className="col-md-3">
              <Link to='#' className="text-decoration-none">  <div className="card rounded-1 border-0 shadow-sm" style={{ backgroundColor: '#00695c', color: '#fff' }}>
                <div className="card-body text-start">
                  <h6> <i className="fas fa-list-ul "></i>&nbsp;&nbsp;Invoice Count: Processing...</h6>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="card-text mb-1">Paid Amt: Processing...</p>
                    <p className="card-text mb-1">Pending Amt: Processing...</p> <br></br>

                  </div>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>



        <div className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="card rounded-1 shadow-sm mt-2 border-0 ">
                  <div className="card-header bg-white mb-0 mt-1 pb-0">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex  mb-0">
                        <p className="me-auto">
                          Change
                          <select
                            className="form-select form-select-sm rounded-1 d-inline mx-2"
                            aria-label="Default select example1"
                            style={{
                              width: "auto",
                              display: "inline-block",
                              fontSize: "12px",
                            }}
                            onChange={handleActionChange}
                          >
                            <option value="">Select Action</option>
                            <option value="Activate">Activate</option>
                            <option value="DeActivate">DeActivate</option>
                            <option value="Assign">Assign</option>
                            <option value="Delete">Delete</option>
                          </select>
                        </p>
                      </div>


                      <div>


                        <ul class="nav nav-underline fs-9" id="myTab" role="tablist">
                          <li>
                            {" "}
                            <a
                              className="nav-link active "
                              id="home-tab"
                              data-bs-toggle="tab"
                              href="#tab-home"
                              role="tab"
                              aria-controls="tab-home"
                              aria-selected="true"
                            >
                              <i class="fa fa-list" aria-hidden="true"></i>    List View
                            </a>
                          </li>
                          <li>

                            <a
                              className="nav-link "
                              id="profile-tab"
                              data-bs-toggle="tab"
                              href="#tab-profile"
                              role="tab"
                              aria-controls="tab-profile"
                              aria-selected="false"
                            >

                              <i class="fa fa-th" aria-hidden="true"></i>  Grid View
                            </a>
                          </li>
                        </ul>


                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="tab-content ">
                      {/* List View */}
                      <div
                        className="tab-pane fade show active"
                        id="tab-home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >


                        <div className="card-table">
                          <div className="table-responsive">
                            <table
                              className=" table table-hover card-table dataTable table-responsive-sm text-center"
                              style={{ color: "#9265cc", fontSize: "13px" }}
                              ref={tableRef}
                            >
                              <thead className="table-light">
                                <tr
                                  style={{
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "12px",
                                  }}
                                >
                                  <th className=" text-start">
                                    <input
                                      type="checkbox"
                                      onChange={handleSelectAll}
                                      checked={
                                        selectedIds.length === agent.length
                                      }
                                    />
                                  </th>
                                  <th className="text-capitalize text-start sortable-handle">
                                    S No
                                  </th>


                                  <th className="text-capitalize text-start sortable-handle">
                                    {" "}
                                    Code
                                  </th>
                                  <th className="text-capitalize text-start sortable-handle">
                                    {" "}
                                    Name
                                  </th>
                                  <th className="text-capitalize text-start sortable-handle">
                                    Email
                                  </th>
                                  <th className="text-capitalize text-start sortable-handle">
                                    Mobile Number
                                  </th>

                                  <th className="text-capitalize text-start sortable-handle">
                                    Created At
                                  </th>
                                  <th className="text-capitalize text-start sortable-handle">
                                    Status
                                  </th>
                                  <th className="text-capitalize text-start sortable-handle">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {agent?.map((data, index) => (
                                  <tr
                                    key={index}
                                    style={{
                                      fontFamily: "Plus Jakarta Sans",
                                      fontSize: "11px",
                                    }}
                                  >
                                    <td className=" text-start">
                                      <input
                                        type="checkbox"
                                        checked={selectedIds.includes(data._id)}
                                        onChange={() => handleCheckboxChange(data._id)}
                                      />
                                    </td>
                                    <td className="text-capitalize text-start text-truncate">
                                      {pagination.from + index + 1}
                                    </td>


                                    <td className="text-capitalize text-start text-truncate">
                                      {data?.agentCode || "Not Available"}
                                    </td>
                                    <td className="text-capitalize text-start text-truncate">
                                      {data?.agentName || "Not Available"}
                                    </td>

                                    <td className=" text-start text-truncate">{data?.email}</td>
                                    <td className="text-capitalize text-start text-truncate">
                                      {data?.mobileNumber || "Not Available"}
                                    </td>

                                    <td className="text-capitalize text-start text-truncate">
                                      {formatDate(
                                        data?.modifiedOn
                                          ? data?.modifiedOn
                                          : data?.createdOn
                                            ? data?.createdOn
                                            : null
                                            || "Not Available")}
                                    </td>
                                    <td className="text-capitalize text-start ">
                                      {data?.isActive || "Not Available"}
                                    </td>
                                    <td className="text-capitalize text-start text-truncate">
                                      <div className="d-flex">
                                        <Link
                                          className="btn btn-sm btn-btn-outline-danger"
                                          to={{
                                            pathname: "/view_agent",
                                            search: `?id=${data?._id}`,
                                          }}
                                        >
                                          <i className="far fa-eye text-primary me-1"></i>
                                        </Link>
                                        <Link
                                          className="btn btn-sm btn-btn-outline-danger"
                                          to={{
                                            pathname: "/edit_agent",
                                            search: `?id=${data?._id}`,
                                          }}
                                        >
                                          <i className="far fa-edit text-warning me-1"></i>
                                        </Link>
                                        <Link
                                          className="btn btn-sm btn-btn-outline-danger"
                                          onClick={() => {
                                            openPopup(data?._id);
                                          }}
                                        >
                                          <i className="far fa-trash-alt text-danger me-1"></i>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>



                      <div
                        class="tab-pane fade " id="tab-profile" role="tabpanel" aria-labelledby="profile-tab"
                      >

                        <div className="container">
                          <div className="row">
                            {agent?.map((data, index) => (
                              <div className="col-md-4 mb-4" key={index}>
                                <div className="card shadow-sm  rounded-1 text-bg-light h-100" style={{ fontSize: '10px' }}>
                                  <div className="card-header   d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0"> {data?.agentName || "Not Available"}</h6>
                                  </div>
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-12 mb-2">
                                        <div className="row">
                                          <div className="col-md-5">
                                            <strong>S.No</strong>
                                          </div>
                                          <div className="col-md-7">
                                            {pagination.from + index + 1}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12 mb-2">
                                        <div className="row">
                                          <div className="col-md-5">
                                            <strong>Agent ID</strong>
                                          </div>
                                          <div className="col-md-7">
                                            {data?.agentCode || "Not Available"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12 mb-2">
                                        <div className="row">
                                          <div className="col-md-5">
                                            <strong>Email ID</strong>
                                          </div>
                                          <div className="col-md-7">
                                            {data?.email}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12 mb-2">
                                        <div className="row">
                                          <div className="col-md-5">
                                            <strong>Primary No</strong>
                                          </div>
                                          <div className="col-md-7">
                                            {data?.mobileNumber || "Not Available"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12 mb-2">
                                        <div className="row">
                                          <div className="col-md-5">
                                            <strong>Created At</strong>
                                          </div>
                                          <div className="col-md-7">
                                            {formatDate(
                                              data?.modifiedOn
                                                ? data?.modifiedOn
                                                : data?.createdOn
                                                  ? data?.createdOn
                                                  : null
                                                  || "Not Available")}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12 mb-2">
                                        <div className="row">
                                          <div className="col-md-5">
                                            <strong>Status</strong>
                                          </div>
                                          <div className="col-md-7 ">

                                            {data?.isActive || "Not Availble"}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-footer bg-light d-flex justify-content-between align-items-center border-top-0">
                                    <Link
                                      className="btn btn-sm btn-outline-primary"
                                      to={{
                                        pathname: "/view_agent",
                                        search: `?id=${data?._id}`,
                                      }}
                                    >
                                      <i className="far fa-eye  me-1"></i>View
                                    </Link>
                                    <Link
                                      className="btn btn-sm btn-outline-warning"
                                      to={{
                                        pathname: "/edit_agent",
                                        search: `?id=${data?._id}`,
                                      }}
                                    >
                                      <i className="far fa-edit  me-1"></i>Edit
                                    </Link>
                                    <Link
                                      className="btn btn-sm btn-outline-danger"
                                      onClick={() => {
                                        openPopup(data?._id);
                                      }}
                                    >
                                      <i className="far fa-trash-alt  me-1"></i>Delete
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>







                      </div>
                    </div>




                    <div className="d-flex justify-content-between align-items-center p-3">
                      <p className="me-auto">
                        Show
                        <select
                          className="form-select form-select-sm rounded-1 d-inline mx-2"
                          aria-label="Default select example1"
                          style={{ width: "auto", display: "inline-block", fontSize: "12px" }}
                          value={pageSize}
                          onChange={handlePageSizeChange} // Handle page size change
                        >
                          <option value="5">5</option>
                          <option value="15">15</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>{" "}
                        Entries out of {pagination.count}
                      </p>
                      <Pagination
                        count={Math.ceil(pagination.count / pageSize)}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open}>
        <DialogContent>
          <div className="text-center m-4">
            <h5 className="mb-4 text-capitalize">
              Are you sure you want to Delete <br /> the selected Agent ?
            </h5>
            <button
              type="button"
              style={{ fontSize: "12px", fontFamily: "Plus Jakarta Sans" }}
              className="btn btn-success  border-0 text-white fw-semibold mx-3"
              onClick={deleteAgentData}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-danger border-0  text-white fw-semibold "
              onClick={closePopup}
              style={{ fontSize: "12px", fontFamily: "Plus Jakarta Sans" }}
            >
              No
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogContent>
          <div className="text-center m-4">
            <h5 className="mb-4"
              style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px" }}>
              Are you sure you want to delete?</h5>
            <button
              type="button"
              className="btn btn-success px-3 py-1 rounded-pill text-uppercase fw-semibold text-white mx-3"
              style={{ fontFamily: "Plus Jakarta Sans", fontSize: "12px" }}
              onClick={deleteSelectedagent}

            >
              Yes
            </button>
            <button
              type="button"
              className="btn btn-danger px-3 py-1 rounded-pill text-uppercase text-white fw-semibold"
              style={{ fontFamily: "Plus Jakarta Sans", fontSize: "12px" }}

              onClick={() => setOpenDelete(false)}
            >
              Cancel
            </button>
          </div>
        </DialogContent>
      </Dialog>


      <Dialog
        open={openAssign}
        onClose={() => setOpenAssign(false)}
        PaperProps={{
          style: {
            width: '600px', // Set custom width
            height: '400px', // Set custom height
            maxWidth: 'none', // Prevents default max-width from Material-UI
          },
        }}
      >
        <DialogContent>
          <div className="text-center m-4">
            <h5 className="mb-4" style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px" }}>
              Assign to Staff
            </h5>

            <form>
              <div className="from-group mb-3">
                <label className="form-label">
                  Staff List
                </label>
                <select
                  className="form-select rounded-1"
                  name="staffName"
                  onChange={handleStaffSelect}  // Capture selected staffId
                >
                  <option value="1">Select a Staff</option>
                  {staff.map((staff, index) => (
                    <option key={index} value={staff._id}>{staff.empName}</option>  // Use staff._id as value
                  ))}
                </select>
              </div>

              <button
                type="button"
                className="btn btn-success mt-4 px-3 py-1 rounded-pill text-uppercase fw-semibold text-white mx-3"
                style={{ fontFamily: "Plus Jakarta Sans", fontSize: "12px" }}
                onClick={handleSubmitStaffAssign}
              >
                Yes
              </button>

              <button
                type="button"
                className="btn btn-danger mt-4 px-3 py-1 rounded-pill text-uppercase text-white fw-semibold"
                style={{ fontFamily: "Plus Jakarta Sans", fontSize: "12px" }}
                onClick={() => setOpenAssign(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openFilter} fullWidth maxWidth="sm">
        <DialogTitle>
          Filter University
          <IconButton className="float-right" onClick={closeFilterPopup}>
            <i className="fa fa-times fa-xs" aria-hidden="true"></i>
          </IconButton>
        </DialogTitle>
        <DialogContent></DialogContent>
      </Dialog>
      <Dialog open={openImport} fullWidth maxWidth="sm">
        <DialogTitle>
          Upload Program List
          <IconButton className="float-right" onClick={closeImportPopup}>
            <i className="fa fa-times fa-xs" aria-hidden="true"></i>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <div className="from-group mb-3">
              <div className="mb-3">
                <input
                  type="file"
                  name="file"
                  className="form-control  text-dark bg-transparent"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div>
              <Link
                to="/ListUniversity"
                className="btn btn-cancel border-0 fw-semibold  text-white float-right bg"
                style={{ backgroundColor: "#0f2239", fontSize: "14px" }}
              >
                Cancel
              </Link>
              <button
                type="submit"
                onClick={handleFileUpload}
                className="btn btn-save border-0 fw-semibold  text-white float-right mx-2"
                style={{ backgroundColor: "#fe5722", fontSize: "14px" }}
              >
                Apply
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
